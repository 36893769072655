import * as React from "react"
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import LayoutUpdate from "../components/layout-update"
import Seo from "../components/seo"
import HeroHomeWithImageBackground from "../components/sections/hero-home-with-image-background"
import IntroHomeNew from "../components/sections/intro-home-new"
import PlansSection from "../components/sections/plans-section"
import SectionMissionMobileNew from "../components/sections/section-mission-mobile-new"
import HomeBannerSectionUpdate from "../components/sections/home-banner-section-update"
import SectionMobileCTAnew from "../components/sections/section-mobile-cta-new"
import SectionMissionNew from "../components/sections/section-mission-new"
import HeroHomeWithImageBackgroundVersion from "../components/sections/hero-home-with-image-background-version"

const SectionMission = loadable(() =>
  pMinDelay(import('../components/sections/section-mission'), 10000),{
    fallback:
      <SectionMissionNew />
  }
)

const HomeVersionPage = () => (
  <LayoutUpdate>
    <Seo
      title="SRA 831(b) for Business Owners"
      description="SRA has made it possible for any successful business to take advantage of the same tools previously reserved for Fortune 500 Companies and utilize an 831(b) Plan to cover uncommon, underinsured or uninsured risks with tax-deferred funds."
      noIndex
    />

    <div className="hero-section">
      <HeroHomeWithImageBackgroundVersion />
    </div>
    
    <HomeBannerSectionUpdate />
    <IntroHomeNew />
    <SectionMission />
    <SectionMissionMobileNew />
    <PlansSection />    
    <SectionMobileCTAnew />
  </LayoutUpdate>
)

export default HomeVersionPage
